import React, { useState, useEffect } from "react";
import "./App.css";
import { Audio } from "react-loader-spinner";
import { supabase } from "./supabaseClient";
import hash from "./hash.js";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import Nutrition from "./Nutrition";

export default function Account({ session, update, wavingToken }) {
  //const clientId = "e06a8e4468034056a35e699e61e7b0cb";
  const clientId = "219f30252d5b48e8ab12b93f4d99d981" //koodos app id
  //const redirectUri = "http://localhost:3000";
  const redirectUri = "https://nutrition-visual.vercel.app/callback";
  const scopes = [
    "user-top-read",
    "user-read-currently-playing",
    "user-read-playback-state",
    "user-follow-read",
    "user-read-private",
  ];
  const axios = require("axios");

  const [loading, setLoading] = useState(false);
  const [topArtists, setTopArtists] = useState([]);
  const [seshToken, setSeshToken] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  //Nutrition Data
  const [timePeriod, setTimePeriod] = useState("short_term");
  const [fiveSongs, setFiveSongs] = useState([]);
  const [avgs, setAvgs] = useState([]); //popularity, danceability, loudness, speechiness, energy
  const [genres, setGenres] = useState([]);
  const [username, setUsername] = useState(null);
  const [followers, setFollowers] = useState(0);
  const getAvg = (arr) => {
    return arr.reduce((a, b) => a + b) / arr.length;
  };

  useEffect(() => {
    const getInfo = async (token) => {
      let avgarr = [];
      var currName;
      var currSongs = [];
      const headers = {
        Authorization: "Bearer " + token,
      };

      await axios
        .get("https://api.spotify.com/v1/me/top/tracks", {
          headers,
          params: { limit: 5, time_range: timePeriod },
        })
        .then(async (response) => {
          let copyarr = await response.data.items.slice();
          console.log(copyarr)
          copyarr.map((song) => {
            currSongs.push(song.name);
          });
          setFiveSongs(copyarr);
          let avg0 = getAvg(
            copyarr.map((song) => {
              return song.popularity;
            })
          );
          avgarr[0] = Math.floor(avg0);

          await axios
            .get(`https://api.spotify.com/v1/audio-features`, {
              headers,
              params: {
                ids: String(
                  copyarr.map((song) => {
                    return song.id;
                  })
                ),
              },
            })
            .then(async (response) => {
              let currData = await response.data.audio_features;
              let avg1 =
                (getAvg(
                  currData.map((song) => {
                    return song.danceability;
                  })
                ) /
                  1) *
                100;
              let avg2 =
                (getAvg(
                  currData.map((song) => {
                    return song.loudness;
                  })
                ) /
                  -60) *
                100;
              let avg3 =
                (getAvg(
                  currData.map((song) => {
                    return song.speechiness;
                  })
                ) /
                  1) *
                100;
              let avg4 =
                (getAvg(
                  currData.map((song) => {
                    return song.energy;
                  })
                ) /
                  1) *
                100;
              avgarr[1] = Math.floor(avg1);
              avgarr[2] = Math.floor(avg2);
              avgarr[3] = Math.floor(avg3);
              avgarr[4] = Math.floor(avg4);
            });
          await axios
            .get(`https://api.spotify.com/v1/artists`, {
              headers,
              params: {
                ids: String(
                  copyarr.map((song) => {
                    return song.artists[0].id;
                  })
                ),
              },
            })
            .then((response) => {
              let genres = response.data.artists.map((artist) => {
                return artist.genres[0];
              });
              let truegenres = genres.map((genre) => {
                if (!genre) {
                  return "unknown";
                }
                return genre;
              });
              setGenres(truegenres);
            });

          setAvgs(avgarr);
        });
      await axios.get("https://api.spotify.com/v1/me", { headers }).then((response) => {
        let currData = response.data;
        setUsername(currData.display_name);
        setFollowers(currData.followers.total);
        if (!hasUpdated) {
          update(currData.display_name, currSongs);
          setHasUpdated(true);
        }
      })


    };

    //call function
    var mToken = wavingToken?wavingToken:hash.access_token;
    if (mToken) {
      setLoading(true);
      setSeshToken(mToken);
      getInfo(mToken);
    }
    setLoading(false);

  }, [timePeriod]);

  return !loading ? (
    <div className="account-background">
      <div className="getemojiswrap">
        {fiveSongs.length == 0 && (
          <div className="prewrap">
            <a
              className="btnn btn--loginApp-link"
              href={`https://accounts.spotify.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
                "%20"
              )}&response_type=token&show_dialog=true`}
            >
              Get Visual
            </a>
          </div>
        )}
        {fiveSongs.length != 0 && (
          <div className="resultswrap">
            <div class="radiowrap">
              <div class="radio_container">
                <input
                  type="radio"
                  name="radio"
                  id="one"
                  onChange={() => {
                    setTimePeriod("short_term");
                  }}

                />
                {timePeriod == "short_term" ?
                  <label className="labelsBold" for="one">
                    Last Month
                  </label> :
                  <label className="labels" for="one">
                    Last Month
                  </label>}
                <input
                  type="radio"
                  name="radio"
                  id="two"
                  onChange={() => {
                    setTimePeriod("medium_term");
                  }}
                />
                {timePeriod == "medium_term" ?
                  <label className="labelsBold" for="two">
                    6 months
                  </label> :
                  <label className="labels" for="two">
                    6 months
                  </label>}
                <input
                  type="radio"
                  name="radio"
                  id="three"
                  onChange={() => {
                    setTimePeriod("long_term");
                  }}
                />
                {timePeriod == "long_term" ?
                  <label className="labelsBold" for="three">
                    All time
                  </label> :
                  <label className="labels" for="three">
                    All time
                  </label>}
              </div>
            </div>
            <br></br>
            <Nutrition
              followers={followers}
              username={username}
              token={seshToken}
              avgs={avgs}
              timePeriod={timePeriod}
              fiveSongs={fiveSongs}
              genres={genres}
            />
            <br></br>
            <br></br>
            <IconContext.Provider value={{ color: "#9AFEE0", className: "global-class-name" }}>
              <div className="bottom-wrap">
                <a
                  className="watermarkcss"
                  href="https://share.koodos.com/nutrition-visual"
                >
                  @koodos
                </a>
                <br></br>
                <div className="igtiktokwrap">
                  <a href="https://share.koodos.com/emojify_koodos_ig">
                    <FaInstagram className="icons0" border />
                  </a>
                  <a href="https://share.koodos.com/emojify_koodos_tiktok">
                    <FaTiktok className="icons" border />
                  </a>
                </div>
              </div>
            </IconContext.Provider>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Audio height="100" width="100" color="grey" ariaLabel="loading" />
  );
}

const calcColor = (popularity) => {
  if (popularity < 8.3) {
    return 0;
  }
  if (popularity < 16.6) {
    return 1;
  }
  if (popularity < 24.9) {
    return 2;
  }
  if (popularity < 33.2) {
    return 3;
  }
  if (popularity < 41.5) {
    return 4;
  }
  if (popularity < 49.8) {
    return 5;
  }
  if (popularity < 58.1) {
    return 6;
  }
  if (popularity < 66.4) {
    return 7;
  }
  if (popularity < 74.7) {
    return 8;
  }
  if (popularity < 83.0) {
    return 9;
  }
  if (popularity < 91.3) {
    return 10;
  }
  return 11;
};
