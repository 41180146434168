import React, { useState, useEffect } from "react";
import { supabase } from './supabaseClient'
import "./App.css";
export default function Auth({}) {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const { error } = await supabase.auth.signIn({
        provider: 'spotify',
      }, {
        scopes: 'user-read-email',
        //redirectTo: 'http://localhost:3000/'
        redirectTo: 'https://nutrition-visual.vercel.app'
      })
      if (error) throw error
      //   alert('Check your email for the login link!')
    } catch (error) {
      console.log(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="row flex flex-center">
      <div className="authwrap" aria-live="polite">
        <form onSubmit={handleLogin}>
          <button className="btnn" aria-live="polite">
            Connect
          </button>
        </form>
      </div>
    </div>
  )
}