import React, { useState, useEffect } from "react";
import './nutrition.scss'
import ReactTooltip from "react-tooltip";
import { AiOutlineInfoCircle } from 'react-icons/ai'
import BlackLogo from './koodoslogoblack.png'
export default function Nutrition({ token, timePeriod, followers, fiveSongs, avgs, genres, username }) {
  const [servingSize, setServingSize] = useState("Last month")
  const axios = require("axios");

  useEffect(() => {
    // const getGenres = async (token) => {
    //   let genrearr = [];
    //   const headers = {
    //     "Authorization": "Bearer " + token
    // };
    //   await axios.get(`https://api.spotify.com/v1/artists`, { headers, params: {ids: String(fiveSongs.map((song)=>{return song.artists[0].id;}))} }).then((response) => {
    //     let genres = response.data.artists.map((artist)=>{return artist.genres[0]})
    //     let truegenres = genres.map((genre)=>{
    //       if(!genre){
    //         return "unknown"
    //       }
    //       return genre;
    //     })
    //     setGenres(truegenres);
    //   })
    // }
    //if(genres.length == 0)
    //getGenres(token);

    //convert time period
    if (timePeriod == "short_term") setServingSize("Last Month")
    if (timePeriod == "long_term") setServingSize("All time")
    if (timePeriod == "medium_term") setServingSize("6 months")


  })
  return (
    <section className="nutrition">
      <ReactTooltip type="dark" place="right" />

      <header>
        <div className="topwrap">
          <div class="head">
            <div className="titlebrought">
              <h1 class="nutrition-title">Nutrition Facts</h1>
              <span className="brought">Brought to you by koodos.com</span>
            </div>
            <img className="black-logo" src={BlackLogo}></img>
          </div>
        </div>
        <div>
          <b>Serving Size</b> {servingSize}
        </div>
      </header>
      <ul class="nutrition-facts">

        <li>
          <span><span class="nutrition-facts-label">Username</span> {username}</span>

        </li>

        <li class="nutrition-facts-section"><span><b>Music Attributes</b></span>% Value*</li>

        <li>
          <span class="nutrition-facts-label"><b>Popularity</b>
            <AiOutlineInfoCircle className="tooltip" data-tip={`How popular your taste is. Healthy Threshold: 30-70%`} />

          </span><b className={(avgs[0] > 30 && avgs[0] < 70) ? "percentageGreen" : "percentageRed"}>{avgs[0]} %</b>
        </li>
        <li>
          <span><b>Danceability</b>
            <AiOutlineInfoCircle className="tooltip" data-tip={`How suitable your taste is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity. Healthy Threshold: 30-70%`} />
          </span> <b className={(avgs[1] > 30 && avgs[1] < 70) ? "percentageGreen" : "percentageRed"}>{avgs[1]} %</b>
        </li>
        <li>
          <span><b>Loudness</b>
            <AiOutlineInfoCircle className="tooltip" data-tip={`The quality of a sound that is the primary psychological correlate of physical strength, aka amplitude. Healthy Threshold: 8-12%`} />
          </span> <b className={(avgs[2] > 8 && avgs[2] < 12) ? "percentageGreen" : "percentageRed"}>{avgs[2]} %</b>
        </li>
        <li>
          <span><b>Speechiness</b>
            <AiOutlineInfoCircle className="tooltip" data-tip={`Detects the presence of spoken words in a track. Healthy Threshold: 10-40%`} />
          </span> <b className={(avgs[3] > 10 && avgs[3] < 40) ? "percentageGreen" : "percentageRed"}>{avgs[3]} %</b>
        </li>
        <li>
          <span><b>Energy</b>
            <AiOutlineInfoCircle className="tooltip" data-tip={`Represents a perceptual measure of intensity and activity based on dynamic range, perceived loudness, timbre, onset rate, and general entropy. Typically, energetic tracks feel fast, loud, and noisy. Healthy Threshold: 30-60%`} />
          </span> <b className={(avgs[4] > 30 && avgs[4] < 60) ? "percentageGreen" : "percentageRed"}>{avgs[4]} %</b>
        </li>


      </ul>
      <ul class="nutrition-facts">
        <li class="nutrition-note"><span><b>Your Top Songs</b></span> </li>

        <li>
          <span><span class="nutrition-facts-label">Song: </span> {fiveSongs[0].name}</span>

          <ul>
            <li><span><b>Artist: </b> {fiveSongs[0].artists[0].name}</span> </li>
          </ul>
        </li>
        <li>
          <span><span class="nutrition-facts-label">Song: </span> {fiveSongs[1].name}</span>

          <ul>
            <li><span><b>Artist: </b> {fiveSongs[1].artists[0].name}</span> </li>
          </ul>
        </li>
        <li>
          <span><span class="nutrition-facts-label">Song: </span> {fiveSongs[2].name}</span>

          <ul>
            <li><span><b>Artist: </b> {fiveSongs[2].artists[0].name}</span> </li>
          </ul>
        </li>
        <li>
          <span><span class="nutrition-facts-label">Song: </span> {fiveSongs[3].name}</span>

          <ul>
            <li><span><b>Artist: </b> {fiveSongs[3].artists[0].name}</span> </li>
          </ul>
        </li>
        <li>
          <span><span class="nutrition-facts-label">Song: </span> {fiveSongs[4].name}</span>

          <ul>
            <li><span><b>Artist: </b> {fiveSongs[4].artists[0].name}</span> </li>
          </ul>
        </li>
      </ul>
      <footer class="nutrition-note">
        * Your Musical Complexion Vitals (MCV) are based on your recent top songs and collectively form your musical diet. A healthy diet consists of a balanced mix of nutrient-dense songs that fall within reasonable range. An MCV that falls below the threshold indicates a deficiency, whereas a value that exceeds the threshold indicates an overdose of that attribute, putting you at risk. Stay safe!!
      </footer>
    </section>
  );
}