import React, { useState, useEffect } from "react";
import TopLogo from './toplogo.png'
import TopBar from './TopBar.js'
import axios from 'axios';
import Nutrition from "./Nutrition";
import "./App.css";
import './index.css'
import { supabase } from './supabaseClient'
import Auth from './Auth'
import Account from './Account'
import { Audio } from 'react-loader-spinner'
import './nutrition.scss'
export default function App() {
  const [session, setSession] = useState(null)
  const [resultsActive, setResultsActive] = useState(false);
  const [authComplete, setAuthComplete] = useState(false);
  const queryParams = new URLSearchParams(window.location.search)
  const authToken = queryParams.get("authToken")
  console.log("token", authToken)

  const updateResults = (username, songs) => {
    setResultsActive(true);
    updateProfile(username, songs);
  }
  const setAuthTrue = () => {
    setAuthComplete(true);
  }
  async function updateProfile(username, songs) {
    console.log("auth user", supabase.auth.user())
    try {
      const user = supabase.auth.user();
      const updates = {
        id: user.id,
        fav_songs: songs,
        spotify_username: username
      };
      const updates2 = {
        email: user.email,
        id: user.id,
        visual: "nutrition",
      }

      let { error } = await supabase.from('nutrition_profiles').upsert(updates);
      if (error) {
        throw error;
      }
      let { error2 } = await supabase.from('profiles').upsert(updates2);
      if (error2) {
        throw error2;
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    setSession(supabase.auth.session())

    supabase.auth.onAuthStateChange((event, session) => {
      setSession(session)
    })
  }, [])

  // return (
  //   <Nutrition/>
  // );
  if (authToken) {
    return (
      <div className="background">
        <TopBar />
        <header className="header1">
          koodosMD
        </header>
        {!resultsActive && !session ?
          <header className="frontstatement">
            How healthy is your music taste?
          </header> : null}
        {!resultsActive && session ?
          <header className="frontstatement">
            Get your musical nutrition breakdown in <br />
            1 click
          </header> : null}

        <body className="container">
          <Account update={updateResults} session={session} wavingToken={authToken} />
        </body>
      </div>
    );
  } else
    return (
      <div className="background">
        <TopBar />
        <header className="header1">
          koodosMD
        </header>
        {!resultsActive && !session ?
          <header className="frontstatement">
            How healthy is your music taste?
          </header> : null}
        {!resultsActive && session ?
          <header className="frontstatement">
            Get your musical nutrition breakdown in <br />
            1 click
          </header> : null}

        <body className="container">
          {!session ? <Auth update={setAuthTrue} /> : <Account update={updateResults} key={session.user.id} session={session} />}
        </body>
      </div>
    );

}
//
